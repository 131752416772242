import React from "react";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "./About.css";
import Whatsapp from "../Whatsapp/Whatsapp";

const About = () => {
  return (
    <>
      <div>
        <Headbar />
        <Whatsapp/>

        <header
          // class="page-header ltx-parallax"
          // style={{ backgroundPosition: "50% -68px;", height: "450px" }}
        >
          <img  class="page-header ltx-parallax" src="./about1.jpg" alt="" style={{ backgroundPosition: "50% -68px;", height:"450px", width:'100%' }}/>
          <div class="container">
          {/* <p style={{fontSize:'40px'}}>ABOUT US</p> */}
            <ul
              class="breadcrumbs"
            >
              <li class="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Home."
                    // href="http://alavion.like-themes.com"
                    class="home"
                  >
                    <span property="name">
                      <Link to="/home "> Home </Link>
                    </span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li class="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name">ABOUT US</span>
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </header>
        <br />
        <br />
        <p style={{ fontSize: "40px", textAlign:'center' }}>ABOUT US</p>
        <br />
        <br />

        <div>
          <div className="media-img"
            style={{
              backgroundImage: 'url("./page 2.jpg")',
              backgroundSize: "",
              backgroundRepeat: "no-repeat",
              textAlign: "justify",
              marginLeft: "1%",
            }}
          >
            <div
            className="media-paragh"
              style={{
                paddingTop: "7%",
                marginLeft: "47%",
                width: "50%",
              }}
            >
              <h6 className="wel">WELCOME TO GLOBE EXPRESS TRAVEL ACADEMY</h6>
              <div className="emp"></div>

              <div className="globe" style={{paddingLeft:'2%'}}>
                Globe Express Travel Academy invites you to the world of
                opportunities, which will help enhance your substantial presence
                in the world of education and enable you to capitalize on the
                growth of the Travel & Tourism industry.
              </div>
              <div className="globe" style={{paddingLeft:'2%'}}>
                The Global Travel & Tourism industry is double the size of
                various other industries. More than 200 million people are
                employed in the industry in such diverse areas as Travel, Sales,
                Ticketing, Hotel Guest Services, Administration, Nature Reserve
                Guides, Cruise Ship, Marketing, Group Sales, Tour Operation,
                Packaging, Niche Specialty-travel Sales and Attraction
                Management among others.
              </div>
              <ul>
                <li className="globe">
                  Travel and Tourism aims to bridge cultural differences,
                  fostering an environment which enriches our lives through the
                  bringing together of people for leisure, family business and
                  educational purposes. Acquire the required knowledge and
                  skills to work in an ever growing industry that contributes to
                  making the world a global village.
                </li>
                <li className="globe">
                  The number of traveling people all over the world is
                  increasing by each year and hence demand for trained and
                  experienced travel consultants is growing,. We have well
                  experienced training professionals to train them in their
                  relevant fields.
                </li>
                <li className="globe">
                  Next to information Technology, Travel & Tourism is the second
                  most competitive industry in the world, and can successfully
                  compete in the global market and has the potential to grow in
                  the global scenario.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <br />
        <br />
        <div>
          <div style={{ height: "" }}>
            <div>
            </div>
            <div
              style={{
                backgroundImage: 'url("./page 2.jpg")',
                backgroundRepeat: "no-repeat",
                paddingLeft: "49%",
                paddingTop: "3%",
              }}
            >
              <h6 className="wel">
                THE BENEFITS OF A CAREER IN TRAVEL & TOURISM INDUSTRY
              </h6>
              <div className="emp"></div>
              <div className="globe">
                Travel & Tourism provides students intellectual development,
                in-depth knowledge of countries and culture, or in meeting the
                professional demands of a constantly evolving work place,
                provides opportunity for interaction across borders and
                international networking.
              </div>
              <div className="globe">
                India is a nation with enormous growth potential that demands us
                to take on the speed and dynamic growth in global Travel and
                Tourism with a legion of well-trained and passionate manpower.
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div>
          <div
            style={{
              backgroundImage: 'url("./page6.jpg")',
              backgroundRepeat: "no-repeat",
              backgroundSize:"40%",
              paddingLeft: "12%",
              paddingTop: "3%",
              height: "auto",
            }}
          >
            <h6 className="wel">SPECIAL FEATURES OF GETA</h6>
            <div className="emp"></div>
            <div className="globe" style={{ width: "800px" }}>
              A Division of GLOBE EXPRESS TRAVEL PVT LTD - an IATA
              (lnternational Air Transport Association) approved travel
              consultant and active member of TAAI (Travel Agents Association of
              India) since 1972, already having made a mark in travel business,
              now owes to educate and motivate dynamic students and
              professionals about the enormous potential employment and business
              opportunities in the industry.
            </div>
            <ul >
              <li className="globe" id="in">Internship for long term courses</li>
              <li className="globe" id="in">
                On job in-house training in Marketing, Ticketing, Visa
                Documentation,Tour Operation and Foreign Exchange
              </li>
              <li className="globe" id="in">
                Highly qualified instructors with industry experience
              </li>
              <li className="globe" id="in">
                Regular Guest Lecturers from industry a 100% job assistance as
                we are a member of TAAI (Travel Agents Association of India)
                with more than 300 travel consultants in the network
              </li>
            </ul>
          </div>
        </div> */}
        <br />

        <div
        className="media-deff"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            backgroundColor: "#f6f4ef",
            paddingTop: "2%",
          }}
        >
          <div>
            <img className="media-photo" src="new05.JPG" style={{ width: "500px", height: "400px" }} />
          </div>

          <div className="media-def" style={{ width: "500px" }}>
            
            <h3 style={{ color: "red" }}>
              THE BENEFITS OF A CAREER IN TRAVEL & TOURISM INDUSTRY
            </h3>
            <div className="globe">
              Travel & Tourism provides students intellectual development,
              in-depth knowledge of countries and culture, or in meeting the
              professional demands of a constantly evolving work place, provides
              opportunity for interaction across borders and international
              networking.
            </div>
            <div className="globe">
              India is a nation with enormous growth potential that demands us
              to take on the speed and dynamic growth in global Travel and
              Tourism with a legion of well-trained and passionate manpower.
            </div>
            <br />
          </div>
        </div>

        <br />
        <br />

        <div
        className="media-deff"
          style={{
            backgroundColor: "#f6f4ef",
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "2%",
          }}
        >
          <div >
            <img className="media-photo" src="/new04.JPG" style={{ width: "500px", height: "500px" }} />
          </div>

          <div className="media-def" style={{ width: "500px" }}>
            <h3 className="media-de" style={{ color: "red" }}> SPECIAL FEATURES OF GETA </h3>
            <div>
              <div className="globe" style={{paddingLeft:'2%'}}>
                A Division of GLOBE EXPRESS TRAVEL PVT LTD - an IATA
                (lnternational Air Transport Association) approved travel
                consultant and active member of TAAI (Travel Agents Association
                of India) since 1972, already having made a mark in travel
                business, now owes to educate and motivate dynamic students and
                professionals about the enormous potential employment and
                business opportunities in the industry.
              </div>
              <ul>
                <li className="globe">Internship for long term courses</li>
                <li className="globe">
                  On job in-house training in Marketing, Ticketing, Visa
                  Documentation,Tour Operation and Foreign Exchange
                </li>
                <li className="globe">
                  Highly qualified instructors with industry experience
                </li>
                <li className="globe">
                  Regular Guest Lecturers from industry a 100% job assistance as
                  we are a member of TAAI (Travel Agents Association of India)
                  with more than 300 travel consultants in the network
                </li>
              </ul>
            </div>
          </div>
        </div>
        <br />
        <br />

        <Bottombar />
      </div>
    </>
  );
};

export default About;
