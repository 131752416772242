import React from "react";
import { Link } from "react-router-dom";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import Whatsapp from "../Whatsapp/Whatsapp";

const Placements = () => {
  return (
    <>
      <Headbar />
      <Whatsapp/>

      <header
        // class="page-header ltx-parallax"
        // style={{ backgroundPosition: "50% -68px;", height: "450px" }}
      >
        <img  class="page-header ltx-parallax" src="./PLACEMENTS.jpg" alt="" style={{ backgroundPosition: "50% -68px;", height:"450px", width:'100%' }}/>
        <div class="container">
          <ul
            class="breadcrumbs"
          >
            <li class="home">
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Go to Home."
                  class="home"
                >
                  <span property="name">
                    <Link to="/home "> Home </Link>
                  </span>
                </a>
                <meta property="position" content="1" />
              </span>
            </li>
            <li class="post post-page current-item">
              <span property="itemListElement" typeof="ListItem">
                <span property="name">PLACEMENTS</span>
                <meta property="position" content="2" />
              </span>
            </li>
          </ul>
        </div>
      </header>
      <br />
      <p style={{ fontSize: "40px", textAlign:'center' }}>PLACEMENTS</p>
        <br />
        <br />
      <div>
        <div
        className="media-ia"
          style={{
            backgroundColor: "#f6f4ef",
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "2%",
          }}
        >
          <div>
            <img
            className="media-iata1"
              src="/new03.JPG"
              style={{
                width: "500px",
                height: "350px",
                marginTop: "2%",
                background: "cover",
              }}
            />
          </div>

          <div className="media-at" style={{ width: "500px" }}>
            <h3 style={{paddingTop:'3%'}}> COLLEGES AND CORPORATE TIE-UPS</h3>
            <div className="emp"></div>

            <ul>
              <li className="globe">
                College and Corporate training handled by experienced faculty
                from the travel industry by Globe Express Travel Academy
              </li>
              <li className="globe">
                Exclusively designed courses for college students, as value
                added certificate course according to their requirement, will be
                provided
              </li>
              <li className="globe">
                The training classes will be conducted in their own college
                campus
              </li>
              <li className="globe">
                Internship will be provided for the students
              </li>
              <li className="globe">Placement Recruitment at college campus</li>
            </ul>
          </div>
        </div>
        <br />

        <Bottombar />
      </div>
    </>
  );
};

export default Placements;
