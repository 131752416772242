import React, { useEffect, useState } from "react";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import "./Blogs.css";
import Whatsapp from "../Whatsapp/Whatsapp";

const Blogs = () => {
  useEffect(() => {
  window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <div>
        <Headbar />
        <Whatsapp/>
        <header
          // class="page-header ltx-parallax"
          // style={{ backgroundPosition: "50% -68px;", height: "450px" }}
        >
          <img  class="page-header ltx-parallax" src="./TESTIMONILAS1.png" alt="" style={{ backgroundPosition: "50% -68px;", height:"450px", width:'100%' }}/>
          <div class="container">
            {/* <p style={{ fontSize: "40px" }}>TESTIMONIALS</p> */}
            <ul
              class="breadcrumbs"
              typeof="BreadcrumbList"
              vocab="https://schema.org/"
            >
              <li class="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Home."
                    href="http://alavion.like-themes.com"
                    class="home"
                  >
                    <span property="name">
                      <Link to="/home "> Home </Link>
                    </span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li class="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name">TESTIMONIALS</span>
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </header>
        <br />
        <p style={{ fontSize: "40px", textAlign:'center' }}>TESTIMONIALS</p>
        <br />
        <br />
        <Container>
          <Row>
            <Col>
              <div class="spa-services">
                <div class="spa-service">
                  <span>
                    <img src="./default1.jpg" style={{ borderRadius: "50%" }} />
                  </span>
                  <div class="spa-service-detail aish">
                    <h5>V ANESSA</h5>
                    <i> Student </i>
                    <p>
                      Very good experience, good atmosphere in the institute.
                      Friendly and experienced staff for teaching. A good
                      destination for career goal.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div class="spa-services">
                <div class="spa-service">
                  <span>
                    <img src="./default1.jpg" style={{ borderRadius: "50%" }} />
                  </span>
                  <div class="spa-service-detail aish">
                    <h5>RACHANA SUDHEER</h5>
                    <i> Student </i>
                    <p>
                    Thanks  to GETA, Bangalore I got placed in Go Air as Ground staff. The excellence of teaching in GETA  is fantastic and job oriented. I strongly recommend aspirants to join this academy and make your career in aviation.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div class="spa-services">
                <div class="spa-service">
                  <span>
                    <img src="./default1.jpg" style={{ borderRadius: "50%" }} />
                  </span>
                  <div class="spa-service-detail aish">
                    <h5>HENISHA D SOUZA</h5>
                    <i> Student </i>
                    <p>
                    GETA is a professional academy with experienced trainers and with 100% job placement guarantee..
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div class="spa-services">
                <div class="spa-service">
                  <span>
                    <img src="./default1.jpg" style={{ borderRadius: "50%" }} />
                  </span>
                  <div class="spa-service-detail aish">
                    <h5>JEEVIKA AS</h5>
                    <i> Student </i>
                    <p>
                    I am really happy with my skill development I have received from this institute it changed my life to begin my aviation career. I am really thankful to all the teachers and their support.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div class="spa-services">
                <div class="spa-service">
                  <span>
                    <img src="./default1.jpg" style={{ borderRadius: "50%" }} />
                  </span>
                  <div class="spa-service-detail aish">
                    <h5>GEDDAM SHIRISH</h5>
                    <i> Student </i>
                    <p>
                    The most amazing and professional aviation academy that i had come across after my +2 , my sincere thanks to the whole team for assisting for placements. I am successful today..
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div class="spa-services">
                <div class="spa-service">
                  <span>
                    <img src="./default1.jpg" style={{ borderRadius: "50%" }} />
                  </span>
                  <div class="spa-service-detail aish">
                    <h5>ELIZABETH</h5>
                    <i> Student </i>
                    <p>
                    Very good experience, good atmosphere in the institute. Friendly and experienced staff for teaching. A good destination for career goal.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Bottombar />
      </div>
    </>
  );
};

export default Blogs;
