import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Home.css";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import { Link } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import Whatsapp from '../Whatsapp//Whatsapp'


const Home = () => {


  return (
    <>
      <div>
        <Headbar />
        <Whatsapp/>
        <Carousel fade>
          <Carousel.Item >
           
            <img className="d-block w-100" src="./airport.jpg" alt="" style={{height:'500px', width:'100%', background:'', opacity:'10'}} />
            <Carousel.Caption>
              {/* <h3>First slide label</h3> */}
          <p  className="media-para" style={{fontSize:'40px', paddingBottom:'20px', color:'white'}}>IATA DIPLOMA</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <img className="d-block w-100" src="./pexels4.jpg" alt="" style={{height:'500px', width:'100%', background:'', opacity:'10'}} />

            {/* <span
              className="d-block w-100"
              // src="/8.jpg"
              alt="Third slide"
              style={{
                backgroundImage: 'url("./page9.jpg")',
                backgroundSize: "botton",
              }}
            /> */}
            

            <Carousel.Caption>
              {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          <p className="media-para" style={{fontSize:'40px', paddingBottom:'20px', color:'white'}}>IATA AVIATION DIPLOMA</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            {/* <span
              className="d-block w-100"
              src="/8.jpg"
              alt="Third slide"
              style={{
                backgroundImage: 'url("./12.jpg")',
                backgroundSize: "cover",
              }}
            /> */}
            <img className="d-block w-100" src="./plane.jpg" alt="" style={{height:'500px', width:'100%', background:'', opacity:'10'}} />


            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
          <p className="media-para" style={{fontSize:'40px', paddingBottom:'20px', color:'white'}}>GETA CERTIFICATE COURSES</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            {/* <span
              className="d-block w-100"
              src="/8.jpg"
              alt="Third slide"
              style={{
                backgroundImage: 'url("./12.jpg")',
                backgroundSize: "cover",
              }}
            /> */}
            <img className="d-block w-100" src="./pexels7.jpg" alt="" style={{height:'500px', width:'100%', background:'', opacity:'10'}} />


            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
          <p className="media-para" style={{fontSize:'40px', paddingBottom:'20px', color:'white'}}>GETA CERTIFICATION PROGRAMME</p>

            </Carousel.Caption>
          </Carousel.Item>





        </Carousel>

        <br />
        <br />
        <div>
          <div className="chair">
            <br />
            <div style={{ marginLeft: "2%", marginTop: "1%" }}>
              <br />
              <img  src="./Chairman.jpg" alt="" style={{ width: "250px" }} />
              <br />
              <br />
            </div>
            <br />
            <div
              id="chair"
              style={{ marginLeft: "5%", textAlign:'justify', marginTop:'2%' }}
            >
              <div className="man" style={{ color: "white", marginBottom:'3%', }}>
              <h6 className="cha">CHAIRMAN </h6>
              <h style={{}} className="cha"> MESSAGE</h>
            </div>
            <div style={{}}>
              <div className="the" >
                {" "}
                The Travel Industry in India and the world is exciting and
                growing at 7% per annum as more and more people travel each
                year. Travel & Tourism is the fastest growing industry in the
                world. In India, tourism is growing with more airlines operating
                flights within the country and more international carriers
                operating into and out of India. The outlook and growth of
                Travel & Tourism is very bright and promising. The growth in
                industry will result in more career opportunities.
              </div>
              <div className="the">
                There are many different aspects of Travel Services ranging from
                Managers Corporate and Leisure, Travel Consultants, Customer
                Service Agents. There are also many career progression options
                such as Tour Manager, Tour Consultants and Reservation Staff.
              </div>
              <div className="the">
                Globe Express Travel Pvt. Ltd (an IATA Recognized Travel Company
                in business for last 40 years) has opened an IATA training
                centre, since 2011 offering many travel courses to enable
                students to excel and earn a place among travel experts in the
                Travel & Tourism Industry. All our Airline & Travel courses come
                with full and unlimited friendly faculty support - so you are
                not alone as your tutor is there to guide and encourage you at
                every step of your way. Place yourself ahead of the competition
                for a bright future by enrolling in one of our recognized Travel
                & Tourism Courses. If you are serious about obtaining employment
                within the Travel Industry or if you are already working within
                the Industry and are keen to upgrade your Curriculum Vitae, GETA
                is the right place to start your career in the aviation
                industry.
              </div>
              <div className="the">
                Finally, at GETA learning is fun and prospects bright. Get
                trained by GETA and become a destination expert and explore the
                world.
              </div>
            </div>
            </div>
          </div>
        </div>

        
        <br />
        <br />
        <div>
          <div style={{ backgroundColor: "#f6f4ef", textAlign: "center" }}>
            <h
              style={{
                color: "red",
                fontSize: "40px",
                fontWeight: 700,
              }}
            >
              Testimonials
            </h>
            <h3> Student FeedBacks </h3>
            <br />
            <div
            className="media-test"
              style={{
                backgroundColor: "#f6f4ef",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <img
                alt=""
                  src="/photo1.jpg"
                  style={{ width: "200px", height: "200px" }}
                />
                <br />
                <br />
                <h6 className="awa">AWATEEF KHAN</h6>
              </div>

              <div className="media-test1" style={{ width: "500px",textAlign:'justify', marginTop:'50px' }}>
                <h
                  style={{
                    color: "red",
                    fontSize: "20px",
                    fontWeight: 500,
                
                  }}
                >
                </h>

                <div >
                  <h style={{ }}>
                    I joined GETA with no knowledge on travel and tourism, was
                    worried that I wouldn’t compete people who already know so
                    much about IATA never I had a hope that I would succeed
                    IATA.. This was all possible because of a supportive
                    staff... Teacher, And today I stand in a reputed travel
                    company a position, a place where I am recognized, working
                    for Travix International Leisure.
                  </h>
                </div>
              </div>
            </div>

            <br />
            <div
                className="media-test"
              style={{
                backgroundColor: "#f6f4ef",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <img
                alt=""
                  src="/photo2.jpg"
                  style={{ width: "200px", height: "200px", }}
                />
                <br />
                <br />

                <h6 className="awa">PANACKAL JOHN</h6>
              </div>

              <div className="media-test1" style={{ width: "500px",textAlign:'justify',marginTop:'50px' }}>
                <h
                  style={{
                    color: "red",
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                >
                  {/* TRAVEL & TOURISM INDUSTRY */}
                </h>

                {/* <h3> THE BENEFITS OF A CAREER IN TRAVEL & TOURISM INDUSTRY </h3> */}
                <div>
                  <h style={{ fontWeight: "  " }}>
                    Amazing skilled trainers that actually we want...easy
                    placements....I got a best placement and better salary only
                    because of these guys... Students who want to be a part of
                    IATA and other course please go head they will shape out u
                    and prepare you..Thanks Globe Express Travel Academy.
                  </h>
                </div>
              </div>
            </div>

            <Link to="/testimonial"><button className="read"
              style={{
                width: "150px",
                height: "60px",
                borderRadius: "100px",
                border: "1px solid #d3c19f",
                backgroundColor: "#fa2f2f",
              }}
            >
            Read more {"-> "}
            </button></Link>
          </div>
        </div>
        <br />
        <Bottombar />
      </div>
    </>
  );
};

export default Home;
