
import './App.css';
import Home from './components/Home/Home';
import About from './components/About/About';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-bootstrap'
import 'bootstrap'
import Bottombar from './components/Bottombar/Bottombar';
import Blogs from './components/Blogs/Blogs';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Course from './components/Course/Course';
import Login from './components/Login/Login';
import Login1 from './components/Login/Login1';
import IATA from './components/IATA/IATA';
import Placements from './components/Placements/Placements';

function App() {
  return (
    <>  
        {/* <Home/> */}
        {/* <About/> */}
        {/* <Blogs/> */}
        {/* <Gallery/> */}
        {/* <Contact/> */}
        {/* <Course/> */}

        <BrowserRouter>
      <Routes>
        <Route >
          <Route index element={<Home/>} />
          <Route path='/home' element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/course" element={<Course/>} /> 
          <Route path="/testimonial" element={<Blogs/>} />
          {/* <Route path="/gallery" element={<Gallery/>} /> */}
          <Route path="/contact" element={<Contact/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/pass" element={<Login1/>} />
          <Route path="/iata" element={<IATA/>} />
          <Route path="/placement" element={<Placements/>} />

        </Route>
      </Routes>
    </BrowserRouter>

    {/* <IATA/> */}
    {/* <Placements/> */}

    {/* <Login/> */}
    {/* <Login1/> */}



    </>
  );
}

export default App;
