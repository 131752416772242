import React from "react";
import { Link } from "react-router-dom";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import Home from "../Home/Home";
import './Login.css'

const Login = () => {
  return (
    <>
      <div>
        <Headbar/>

        <header
          class="page-header ltx-parallax"
          style={{ backgroundPosition: "50% -68px;", height: "450px" }}
        >
          <div class="container">
            <h1>My Account</h1>
            <ul
              class="breadcrumbs"
              typeof="BreadcrumbList"
              vocab="https://schema.org/"
            >
              <li class="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Home."
                    href="http://alavion.like-themes.com"
                    class="home"
                  >
                    <span property="name"><Link to="/home "> Home </Link></span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li class="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name">My Account</span>
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </header>

        <div>
          <div class="col-xl-9 col-lg-8 col-md-12 col-xs-12 text-page">
            <article
              id="post-617"
              class="post-617 page type-page status-publish hentry"
            >
              <div class="entry-content clearfix" id="entry-div">
                <div class="woocommerce">
                  <div class="woocommerce-notices-wrapper"></div>
                  <h2>Login</h2>
                  <form
                    class="woocommerce-form woocommerce-form-login login"
                    method="post"
                  >
                    <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                      {" "}
                      <label for="username">
                        Username or email address&nbsp;
                        <span class="required">*</span>
                      </label>{" "}
                      <input
                        type="text"
                        class="woocommerce-Input woocommerce-Input--text input-text"
                        name="username"
                        id="username"
                        autocomplete="username"
                        // value=""
                      />
                    </p>
                    <p class="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                      {" "}
                      <label for="password">
                        Password&nbsp;<span class="required">*</span>
                      </label>{" "}
                      <input
                        class="woocommerce-Input woocommerce-Input--text input-text"
                        type="password"
                        name="password"
                        id="password"
                        autocomplete="current-password"
                      />
                    </p>
                    <p class="form-row">
                      {" "}
                      <input
                        type="hidden"
                        id="woocommerce-login-nonce"
                        name="woocommerce-login-nonce"
                        // value="bf9be4ac26"
                      />
                      <input
                        type="hidden"
                        // name="_wp_http_referer"
                        // value="/my-account/"
                      />{" "}
                      <span class="ltx-btn-wrap ltx-btn-wrap-main ltx-btn-wrap-hover-black">
                        <div
                          // type="submit"
                          class="woocommerce-Button btn btn-xs btn-main color-hover-black"
                          // name="login"
                          // value="Log in"
                        >
                          Log in
                        </div>
                      </span>{" "}
                      <label class="woocommerce-form__label woocommerce-form__label-for-checkbox inline" >
                        {" "}
                        <input
                          class="woocommerce-form__input woocommerce-form__input-checkbox"
                          name="rememberme"
                          type="checkbox"
                          id="rememberme"
                          value="forever"
                        />{" "}
                        <span>Remember me</span>{" "}
                      </label>
                    </p>
                    <p class="woocommerce-LostPassword lost_password" >
                      {" "}
                      <a href="" style={{color:'#e73030'}}>
                        <Link to='/pass'>
                        Lost your password?
                        </Link>
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </div>

        <Bottombar />
      </div>
    </>
  );
};

export default Login;
