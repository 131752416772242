import React,{useEffect, useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "./Headbar.css";
import { SlUser } from "react-icons/sl";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

import { MDBIcon } from "mdb-react-ui-kit";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';

const Headbar = () => {

  return (
    <>  
    <div>
      <div style={{backgroundColor:'#1067a7', color:'white',}}>
        <ul className="info" style={{display:'flex', gap:'50px', listDecoration:'none', height:'40px', paddingTop:'5px',listStyle:"none", marginLeft:'145px'}}>
          <li>Email : tours@globextravels.com</li>
          <li>Mob : +91 8746010999 </li>
          <li>Tel : +91 080 22112330, +91 080 22112331</li>
          <li>Monday to Saturday - 10:00 am - 06:00 pm</li>
        </ul>
      </div>
    </div>
      {/* <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container" id="contain">
          <a class="navbar-brand" href="#">import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

            <img className="media-imga"
              src="/logo.JPG"
              alt=""
              style={{ height: "90px", backgroundImage: "white" }}
            />
          </a>
         

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-5">
              <li class="nav-item">
              <Link to='/home'>
                <a class="nav-link" href="#" >
                 
                  Home
                </a>
                </Link>
              </li>
              <li class="nav-item">
                <Link to='/about'> 
                <a class="nav-link" href="#" 
                 >
                  About Us
                </a>import Nav from 'react-bootstrap/Nav';
                </Link>
              </li>
              <li class="nav-item">
                <Link to='/iata'> 
                <a class="nav-link" href="#"  >
                  IATA
                </a>
                </Link>
              </li>
              <li class="nav-item">
                <Link to='/place'> 
                <a class="nav-link">
                  Placements
                </a>
                </Link>
              </li>
              <li class="nav-item">
              <Link to='/course'> 
                <a class="nav-link" href="#" >
                  Courses
                </a>
                </Link>
              </li>
              <li class="nav-item">
              <Link to='/blogs'> 
                <a class="nav-link" href="#" >
                  Testimonials
                </a>
                </Link>
              </li>
             
              <li class="nav-item">
              <Link to='/contact'> 
                <a class="nav-link" href="#" >
                  Contact Us
                </a>
                </Link>
              </li>
            </ul>
          </div>
        
        </div>
      </nav> */}


<Navbar className="" collapseOnSelect expand="lg" bg="light" variant="dark">
      <Container className="hea" >
        <Navbar.Brand href="#home"> <img className="media-imga"
              src="/logo.JPG"
              alt=""
              style={{ height: "85px", marginTop:'15%' }}
            /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Link to='/home'>
                <a class="nav-link" href="#" >
                 
                  Home
                </a>
                </Link>
                <Link to='/about'> 
                <a class="nav-link" href="#" 
                 >
                  About Us
                </a>
                </Link>
                <Link to='/iata'> 
                <a class="nav-link" href="#"  >
                  IATA
                </a>
                </Link>
                <Link to='/placement'> 
                <a class="nav-link">
                  Placements
                </a>
                </Link>
                <Link to='/course'> 
                <a class="nav-link" href="#" >
                  Courses
                </a>
                </Link>
              <Link to='/testimonial'> 
                <a class="nav-link" href="#" >
                  Testimonials
                </a>
                </Link>
                <Link to='/contact'> 
                <a class="nav-link" href="#" >
                  Contact Us
                </a>
                </Link>       
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
};

export default Headbar;
