import React from "react";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import { Link } from "react-router-dom";
import "./Contact.css";
import Whatsapp from "../Whatsapp/Whatsapp";


const Contact = () => {
  return (
    <>
      <div>
        <Headbar />
        <Whatsapp/>

        <header
        //   class="page-header ltx-parallax"
        //   style={{ backgroundPosition: "50% -68px;", height: "450px" }}
        >
			<img  class="page-header ltx-parallax" src="./contact-us1.jpg" alt="" style={{ backgroundPosition: "50% -68px;", height:"450px", width:'100%' }}/>
          <div class="container">
            {/* <p style={{fontSize:'40px'}}>CONTACT US</p> */}
            <ul
              class="breadcrumbs"
            >
              <li class="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Home."
                    class="home"
                  >
                    <span property="name">
                      <Link to="/home "> Home </Link>
                    </span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li class="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name">CONTACT US</span>
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </header>
        <br />
        <p style={{ fontSize: "40px", textAlign: "center" }}>CONTACT US</p>
        {/* <br /> */}
        {/* <br /> */}

        <div className="">
        <section class="contact-details ptb--60">
	<div class="container">
		<div class="row" id="row">
			<div class="col-md-6">
				{/* <h1 class="heading text-center">Contact Us</h1>  */}
				<div class="col-md-12">
					<div class="card">
						<div class="icon">
							<i class="fa fa-map-marker" aria-hidden="true"></i>
						</div>
						<div class="card-body">
							<h1 class="card-title">Address</h1>
							<p>GLOBE EXPRESS TRAVEL ACADEMY PVT. LTD. Parvathi Plaza, No. 105, Richmond Road,<br/>Richmond Town,  <br/>Bangalore - 560025</p>
						</div>
					</div>
				</div>
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-6">
							<div class="card">
								<div class="icon">
									<i class="fa fa-phone" aria-hidden="true"></i>
								</div>
								<div class="card-body">
									<h1 class="card-title">Contact</h1>
									<span class="bottom-line" > Mob - +91 8746010999 </span>
									<br />
									<span class="bottom-line" > Tel - +91 080 22112330, <br /> +91 080 22112331</span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="icon">
									<i class="fa fa-envelope" aria-hidden="true"></i>
								</div>
								<div class="card-body">
									<h1 class="card-title">Email</h1>
									<a class="bottom-line" href="mailto:contact@lastdate.info">tours@globextravels.com</a>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
			</div>
			<div class="col-md-6">
				<h1 class="heading text-center">Get in touch</h1> 
				 <form class=""  method="post" enctype="multipart/form-data">
				<div class="form-group">
						<input class="form-control" id="name" name="name" type="text" placeholder="Your Name"/>
					</div>
					
					<div class="form-group">
						<input class="form-control" id="email" name="email" type="text" placeholder="E-mail"/>
					</div>
					
					<div class="form-group">
						<input class="form-control" id="telephone" name="mobile" type="text" placeholder="Mobile"/>
					</div> 
			  
					<div class="form-group">
						<textarea class="form-control" cols="30" rows="5" name="message" placeholder="Your Comments here.."></textarea>
					</div>

					<div class="form-group">
						<div class="btn btn-submit">Submit</div>
					</div>

				</form>
			</div>
			
		</div>
	</div>

</section>
        </div>

        <br />
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.112894270556!2d77.5949866140476!3d12.964627190860446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1534d743186f%3A0x942f9a2edeb0dac4!2sGlobe%20Express%20Travel%20Academy%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1670991323797!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0", width: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <Bottombar />
      </div>
    </>
  );
};

export default Contact;
