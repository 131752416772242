import React from "react";

import Accordion from "react-bootstrap/Accordion";
import "./Course.css";
import { Link } from "react-router-dom";
import Headbar from "../Headbar/Headbar";
import Bottombar from "../Bottombar/Bottombar";
import Whatsapp from "../Whatsapp/Whatsapp";

const Course = () => {
  return (
    <>
      <div>
        <Headbar />
        <Whatsapp/>
        <header
          // class="page-header ltx-parallax"
          // style={{ backgroundPosition: "50% -68px;", height: "450px", }}
        >
          <img  class="page-header ltx-parallax" src="./diploma1.jpg" alt="" style={{ backgroundPosition: "50% -68px;", height:"450px", width:'100%' }}/>
          <div class="container">
          {/* <p style={{fontSize:'40px'}}>COURSES</p> */}
            <ul
              class="breadcrumbs"
            >
              <li class="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Home."
                    href="http://alavion.like-themes.com"
                    class="home"
                  >
                    <span property="name"><Link to="/home "> Home </Link></span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li class="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name">COURSES  </span>
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </header>
        <br />
        <p style={{ fontSize: "40px", textAlign:'center' }}>COURSES</p>
        <br />
        <br />

        <div className="back">
          {/* <h1 style={{ textAlign: "center" }}>Courses</h1> */}
          <br />
          <Accordion>
            <h3 className="dip" style={{ textAlign: "center" }}>IATA DIPLOMA</h3>
            <h6 className="dip" style={{ textAlign: "center" }}>
              Global Certification Programmes
            </h6>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span className="cou"> Course :</span>
                <span className="cour">Diploma in Travel and Tourism Foundation (DTTF)Level -1</span>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    13 Modules – Fare Calculation/Basic practicalities of Travel
                    and Tourism, Travel & Tourism Industry Regulations, Codes
                    and World Geography – An introduction to Hotels – The other
                    means of transport (Global Car Rental, Railways, Cruise) –
                    The Airport and Airline Essentials – Fares and Ticketing
                    including E- ticketing – Travel documents Passport, Visa,
                    Health Travel Insurance, Customs, Currency- The importance
                    of exceeding cus- tomer expectations – The role of
                    technology in the Travel Industry – GDS (Global Distribution
                    System)- Computer based tool – Amadeus/Galileo
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Airline Reservation Agent – Ground Staff – Customer Service,
                    Travel Agents – Travel Consultant – Ticketing Executive,
                    MNC/Hotel Travel Desk Executive – Tour Operator, Travel BPO
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II / Any Degree / Any Master Degree</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
    
            <Accordion.Item eventKey="1">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour">Diploma in Travel and Tourism Consultant (DTTC) Level - 2</span>
              
                
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    9 Modules – Fare Calculation/in- depth knowledge of the most
                    popular international Travel & Tourism destinations – Advice
                    and guide travelers on rail, bus, coaches, various
                    accommodation cruises and package tours – Interpret IATA
                    resolutions as they apply to accredited travel agencies –
                    Adopt effective selling skills in consultancy roles –
                    Construct more complex fare & routing and manage ticket
                    exchange – Follow the BSP (Billing and Settlement Plan)
                    Procedures, In-depth practicalities of Travel and Tourism.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Airline Reservation Agent - Travel Agents – Travel
                    Consultant – Senior Ticketing Executive, MNC/Hotel Travel
                    Consultant – Tour Operator /Manager, Travel BPO
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>
                    PUC II / Any Degree / Any Master Degree/IATA Foundation
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            
            <Accordion.Item eventKey="2">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour">Managing the Travel Business Diploma (DTTM) Level - 3</span>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    7 Modules _ Leadership Skills for Managers/Business owners.
                    Management roles and responsibilities in the Travel Industry
                    with organization – motivate and inspire employers- gain the
                    tools you need for negotiating – Assess your business and
                    learn about different Special Travel and Tour Production –
                    Administration and Accounting – Strategy and Management –
                    Tour Packages – Sales and Marketing social media strategy
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Airline and Travel Managers – Senior Travel Consultant –
                    MNC/Hotel Travel Manager- Tour Manager, Travel BPO Manager
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>
                    Minimum 2 years work experience in high level supervisory
                    with any De- gree/Any Master Degree/IATA
                    Foundation/Consultant
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour"> Diploma in Air Cargo Introductory</span>
               
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>3 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    11 Modules- Understanding in Management, Organizational
                    Skills of a Professional Cargo Agent- Identify Air Cargo
                    Terms & Expressions – Control and maximize the use of space
                    in aircraft holds – Understand Aircraft Routings and
                    Industry Manuals such as TACT and OAG world Airline guides –
                    Apply basic routing principles and operator variation –
                    Follow IATA standards to accept cargo and complete
                    documentations and improve customer service – Learn Airways
                    Bill completion and basic rating Operation of Freight
                    Forwarders, Airline Cargo Units – IATA Geography, basic
                    cargo procedures.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Cargo Airline Supervisor – Cargo Agent – Freight Forwarder
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II / Any Degree / Any Master Degree</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br />
            <h3 className="dip" style={{ textAlign: "center" }}>
              IATA AVIATION DIPLOMA 
            </h3>
            <h6 className="dip" style={{ textAlign: "center" }}>Global Certification Programmes</h6>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour">Certificate in Airport Operation</span>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    6 Modules – Understanding the Airport – The Airport as an
                    operational system – Security – Support Services – Airport
                    issues and challenges – The Future of the Airport.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Ground Staff / Safety & Security Officer / Airport Staff
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II / Any Degree / Any Master Degree</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour">  Certificate in Passaenger Ground Service</span>
               
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    9 Modules – Introduction to Airline & Airport ground
                    operation – Computer Reservation and Departure Control
                    Systems Function – Check-in process for the passenger and
                    their baggage (Airport and Off-site) – Condition of carriage
                    – boarding procedures and Dangerous goods regulations -
                    Manager-Passenger interaction – Aviation Security
                    requirement for passenger and baggage – Enhanced passenger
                    facilitation – Future in Industry.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Reservation Staff / Ground Staff/Safety & Security Officer
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II / Any Degree / Any Master Degree</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          
            <Accordion.Item eventKey="6">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour"> Certificate in Airline Customer Service</span>
                
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    The Modules consist of improved standard of customer service
                    = Verbal and Non-verbal communications skills-Customer
                    contact techniques _ cross cultural awareness-Managing
                    stress.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Ground Staff/Guest Relations Executive/Customer Care
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II / Any Degree / Any Master Degree</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">EXAM SESSION :</span>
                  <span>MARCH/ JUNE/ SEPTEMBER/DECEMBER every year</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br />
            <h3 className="dip" style={{ textAlign: "center" }}>GETA CERTIFICATE COURSES</h3>
            {/* <h6 className="dip" style={{ textAlign: "center" }}>
              GETA Certificate short term courses designed by experienced
              fraternity from the Travel & Tourism Industry to suit the growing
              demand for highly skilled personnel.
            </h6 > */}
            <Accordion.Item eventKey="7">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour"> Certificate in Travel Management and Ticketing</span>
                
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>4 Months</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    Basic practicalities of Travel and Tourism, Travel & Tourism
                    Industry regulations, Codes and World Geography – The basics
                    about travel products – The Airport and Airline formalities
                    and Customer Service – Fares and E-ticketing – Travel
                    documents: Passport, Visa, Health Travel Insurance, Customs,
                    Currency – Customer Service – Corporate Communication –
                    Personality grooming – Computer Reservation System (Galileo)
                  </span>
                </div>
                {/* <hr />
          <div>
            <span className="Duration">RECOMMENDED :</span>
            <span>COURSE CONTENT</span>
          </div> */}
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Airline Reservation Agent – Ground Staff – Customer Service,
                    Travel Agent-Travel Consultant-Ticketing Executive,
                    MNC/Hotel Travel Desk Executive – Tour Opera- tor, Travel
                    BPO.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II / Any Degree / Any Master Degree</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br />
            <h3 className="dip" style={{ textAlign: "center" }}>
              GETA CERTIFICATION PROGRAMME
            </h3>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour"> P.G Diploma in Travel AND Tourism</span>
                
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>1 Year</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    Semester 1- Travel Terminology & Formalities/ Documents-
                    Role of Travel Agency & its Operations, Travel Retail &
                    Fairs, International Travel Organisations, World Geography,
                    Introduction to Travel marketing, Customer Service
                    Management, Aircraft & in flight Service, E-ticketing and
                    Fare Construction. Semester II – Indian tourism, Economics &
                    Social aspects of tourism, Ecological & Environmental
                    aspects of Tourism, Attractions, Planning Travel Itinerary,
                    Marketing concepts in Tourism, Transport and tours, Tourism
                    Planning & Environment, Information Technology in Tourism
                    and Business Tourism.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">RECOMMENDED :</span>
                  <span>
                    For individuals who seek their career as travel consultant.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Travel Consultancy, Tours Operators, Travel desk Hotel and
                    MNC
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>Any Degree/ Any Master Degree</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            
            <Accordion.Item eventKey="9">
              <Accordion.Header>
              <span className="cou"> Course :</span>
                <span className="cour"> U.G. Diploma in Travel and Tourism</span>
                
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <span className="Duration">DURATION :</span>
                  <span>1 Year</span>
                </div>
                <hr />
                <div>
                  <span className="Duration">COURSE CONTENT :</span>
                  <span>
                    Semester 1- Travel Terminology & Formalities/
                    Documents-Introduction to Travel Agency Operations, World
                    Geography, International Travel Organization, Aircraft &
                    in-flight services. Semester II – Indian Tourism,
                    attractions, Planning Travel Itinerary, Market Concept in
                    Tourism, Rail/Water transport, I-ticket and Fare
                    Construction.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">RECOMMENDED :</span>
                  <span>
                    For individuals who seek their career as travel & Tourism
                    Industry.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">JOB OPPORTUNITY :</span>
                  <span>
                    Travel Agency, Tours Operators, Tourism Boards, Travel desk.
                  </span>
                </div>
                <hr />
                <div>
                  <span className="Duration">MINIMUM QUALIFICATION:</span>
                  <span>PUC II</span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* <br/>
      <Accordion.Item eventKey="10">
        <Accordion.Header>Diploma in Travel and Tourism Foundation (DTTF)Level -1</Accordion.Header>
        <Accordion.Body>
          <div>
            <span className="Duration">DURATION :</span>
            <span>DURATION</span>
          </div>
          <hr />
          <div>
            <span className="Duration">COURSE CONTENT :</span>
            <span>COURSE CONTENT</span>
          </div>
          <hr />
          <div>
            <span className="Duration">RECOMMENDED :</span>
            <span>COURSE CONTENT</span>
          </div>
          <hr />
          <div>
            <span className="Duration">JOB OPPORTUNITY :</span>
            <span>COURSE CONTENT</span>
          </div>
          <hr />
          <div>
            <span className="Duration">MINIMUM QUALIFICATION:</span>
            <span>COURSE CONTENT</span>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}
          </Accordion>
        </div>
        <br />

        <Bottombar />
      </div>
    </>
  );
};

export default Course;
