import React from "react";
import "./Bottombar.css";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { BsFacebook } from "react-icons/bs";
import { TiSocialTwitter } from "react-icons/ti";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { GrGooglePlus } from "react-icons/gr";
import { Link } from "react-router-dom";

const Bottombar = () => {
  return (
    <>
      <div
        className="fotter"
        style={{
          backgroundColor: "#1067a7",
          color: "white !important",
          padding: "25px",
          textAlign:'justify',
          justifyItems:'space-evenly'
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="tre-0">
                <h4>About Us</h4>
                <p>
                  Globe Express Travel Academy invites you to the world of
                  opportunities, which will help enhance your substantial
                  presence in the world of education and enable you to
                  capitalize on the growth of the Travel & Tourism industry.
                </p>
                <div className="social">
                  <ul
                    style={{ display: "flex", listStyle: "none", gap: "10px" }}
                  >
                    <li>
                      <BsFacebook />
                    </li>
                    <li>
                      <TiSocialTwitter />
                    </li>
                    <li>
                      <AiOutlineInstagram />
                    </li>
                    <li>
                      <AiOutlineYoutube />
                    </li>
                    <li>
                      <GrGooglePlus />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tre-o">
                <h5 id="fot">Quick Links</h5>
                <ul>
                  <li><Link to='/home'>Home</Link></li>
                  <li><Link to='/about'>About Us</Link></li>
                  <li><Link to='/iata'>IATA</Link></li>
                  <li><Link to='/placement'>Placements</Link></li>
                  <li><Link to='/course'>Courses</Link></li>
                  <li><Link to='/testimonial'>Testimonials</Link></li>
                  <li><Link to='/contact'>Contact US</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tre-o">
                <h5 id="foot" >Our Courses</h5>
                <ul><Link to='/course'>
                  <li>IATA DIPLOMA</li>
                  <li>IATA AVIATION DIPLOMA</li>
                  <li>GETA CERTIFICATE COURSES</li>
                  <li>GETA CERTIFICATION PROGRAMME</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tre-o">
                <h5 id="foot">Our Address</h5>
                <ul >
                <Link to='/contact'>
                  <li>GLOBE EXPRESS TRAVEL ACADEMY PVT. LTD.
Parvathi Plaza, No. 105, Richmond Road,
Richmond Town, Bangalore - 560 025</li>
                  {/* <li>Mob : +91 96868 21357</li> */}
                  <li>Mob : +91 8746010999</li>
                  <li>Tel : +91 080 22112330</li>
                  <li>Tel : +91 080 22112331</li>
                  <li>tours@globextravels.com</li>
                  {/* <li>admissions@globexacademy.in</li> */}
                  <li>www.globexacademy.in</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{textAlign:'center'}}>
      <span> Copyright © 2022 All rights reserved, Designed and Developed by</span>
      <span><a id='id' href="https://parnetsgroup.com/" target='_blank' style={{color:'#1067a7 !important'}}> ParNets Group </a></span>
      </div>
    </>
  );
};

export default Bottombar;
