import React from "react";
import { Link } from "react-router-dom";
import Bottombar from "../Bottombar/Bottombar";
import Headbar from "../Headbar/Headbar";
import Whatsapp from "../Whatsapp/Whatsapp";


const IATA = () => {
  return (
    <>
      <div>
      <Headbar />
      <Whatsapp/>
      <header
          // class="photo1"
          //  id="photo1"
          // style={{ backgroundPosition: "50% -68px;", height: "450px" }}
        >
          <img  class="page-header ltx-parallax" src="./iata1.jpg" alt="" style={{ backgroundPosition: "50% -68px;", height:"450px", width:'100%' }}/>
          <div class="container">
          {/* <p style={{fontSize:'40px'}}>IATA</p> */}
            <ul
              class="breadcrumbs"
            >
              <li class="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Home."
                    class="home"
                  >
                    <span property="name">
                      <Link to="/home "> Home </Link>
                    </span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li class="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span className="media-ia1" property="name">IATA</span>
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </header>
        <br />
        <p  style={{ fontSize: "40px", textAlign:'center' }}>IATA</p>
        <br />
        <br />

        <div
        className="media-ia"
          style={{
            backgroundColor: "#f6f4ef",
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "2%",
          }}
        >
          <div>
            <img className="media-iata" src="/aviation1.webp" style={{ width: "500px", height: "400px", background:'cover', marginTop:'15%' }} />
          </div>

          <div className="media-at"  style={{ width: "500px" }}>
            <h3 className="media-ia1" style={{}}> IATA</h3>
            <div className="emp"></div>

            <div>
              <div className="globe">
                The international Air Transport Association (IATA) - the 60
                years old global trade organization - was founded in 1945.
                IATA’s mission is to represent, lead and serve the Airline
                industry. IATA being the global representative of the
                international air transport industry, has over 260 members
                representing nearly 95% of international scheduled air traffic
                operations.
              </div>
              <div className="globe">
                The IATA Training and Development institute (lTDl), offers
                internationally recognized programs such as airline, airport,
                civil aviation, cargo and travel & tourism training and
                leadership development programmes leading to internationally
                recognized certificates and diplomas to develop human capital
                for tomorrow’s air transport industry.
              </div>
              <div className="globe">
                Its renowned expertise and state of the art tools and technology
                will help you effectively enhance your knowledge and skills to
                earn your place among air travel experts.
              </div>
              <div className="globe">
                Over the past 40 years some 300,000 students have enrolled and
                studied in this programme.
              </div>
            </div>
          </div>
        </div>
        <br />

        <div>

        <div
        className="media-ia"
          style={{
            backgroundColor: "#f6f4ef",
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "2%",
          }}
        >
          <div>
            <img className="media-iata1" src="/online759.jpg" style={{ width: "500px", height: "500px", marginTop:'14%', background:'cover' }} />
          </div>

          <div className="media-at" style={{ width: "500px" }}>
            <h3 className="media-value" style={{}}> VALUE OF IATA CERTIFICATION </h3>
            <div className="emp"></div>

            <div>
              <ul className="globe">
                <li>
                Industry’s leading international travel professional training
                </li>
                <li>
                World-wide recognitions and acceptance
                </li>
                <li>
                A high standard of training and a reliable brand
                </li>
                <li>
                More than 30 years of training experience
                </li>
                <li>
                Key to upgrade your professional competence or to pursue a career
in the industry.
                </li>
              </ul>

              <h3 style={{}}> ASSOCIATED WITH THE BEST CRS (GALILEO) </h3>
              <div className="globe">
              Galileo is the National distribution company of Galileo international, a
leading travel and technology distribution company with headquarters
in Parsippany, New Jersey, USA.
              </div>
              <div className="globe">
              The Galileo CRS provides instant access to the widest range of travel
vendors, automated pricing of the most complex flight itineraries, and
a variety of ticketing products, fully customized client documentation,
technical solutions and tools designed for all types of agencies. Galileo
India reaches out to nearly 4000 agency locations and enjoys significant
market share.
              </div>
              <div className="globe">
              Travel Port Galileo, one of the world’s leading providers of electronic
global distribution services, connects to 350 airlines, 52 low cost carriers,
25 car rental companies, 89,000 hotel properties, wide array of tour
operators and cruise lines throughout the world.
              </div>
            </div>
          </div>
        </div>

        </div>
        <br />
        <Bottombar/>


      </div>
    </>
  );
};

export default IATA;
